import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SHOW_PRICE_TYPES } from '../../../config/constants/deals';
import {
  PAGE_TYPES,
  VIP_QUALIFIERS,
  DEAL_PAGE_PATH_QUALIFIERS,
} from '../../../config/constants/page-types';
import { isCreditecDeal } from '../../../helpers/creditec';
import {
  showPriceType,
  splatPriceABTest,
  splatPriceDisplayDiscountAndOriginalPrice,
  splatPriceDisplayEstimatedText,
} from '../../../helpers/deals';
import { parseWowcherPath } from '../../../helpers/url';
import NormalPriceSplat from './NormalPriceSplat';
import VipDealPriceSplat from './VipDealPriceSplat';

const DealPriceSplat = ({
  className,
  coinFlipAnimation = false,
  columnSelection,
  deal = {},
  discount = false,
  forcePrice = true,
  isHideIfNoDiscount = true,
  isHideSplat = false,
  isProductPage,
  rowSelection,
  secondCheckout,
  visible = true,
}) => {
  const [showVipSplatComponent, setShowVipSplatComponent] = useState(false);

  const [isVipUser, basketTotal] = useSelector((state) => [
    state?.user?.userprofile?.vipUser,
    state?.user?.basketTotal,
  ]);

  const router = useRouter();
  const path = router.asPath || '';
  const pathUrl = parseWowcherPath(path);

  const isVipHubPage = VIP_QUALIFIERS.includes(pathUrl.pageType);
  const isDealPage = DEAL_PAGE_PATH_QUALIFIERS.includes(pathUrl.pageType);
  const isSearchPageVipFilter =
    PAGE_TYPES.search.includes(pathUrl.pageType) && router.query?.vip;
  const isMarketingCreditDeal = deal?.display?.vipDiscountAllowed;
  const isVipExclusiveDeal = deal?.display?.vipExclusive;
  const isEarlyBirdPage = PAGE_TYPES.earlyBird.includes(pathUrl.pageType);
  // Show Vip Exclusive Graphic only on Deal pages, Early Bird Vip Hub and
  // search page only when vipSearch Filter is on.
  const showVipSplat =
    isVipUser &&
    (isMarketingCreditDeal ||
      isVipExclusiveDeal ||
      isEarlyBirdPage ||
      isVipHubPage) &&
    (isSearchPageVipFilter || isVipHubPage || isDealPage || isEarlyBirdPage);

  const priceType = showPriceType(
    deal,
    discount,
    forcePrice,
    isHideIfNoDiscount,
  );

  if (splatPriceDisplayEstimatedText({ deal })) forcePrice = true;

  useEffect(() => {
    if (showVipSplat && visible) {
      if (coinFlipAnimation) {
        const timeoutId = setTimeout(() => {
          setShowVipSplatComponent(true);
        }, 1_500);

        return () => clearTimeout(timeoutId);
      } else {
        setShowVipSplatComponent(true);
      }
    } else {
      setShowVipSplatComponent(false);
    }
  }, [showVipSplat, visible, coinFlipAnimation]);
  // hide splat
  if (
    priceType === SHOW_PRICE_TYPES.hidden ||
    isHideSplat ||
    isCreditecDeal(deal)
  )
    return null;

  if (
    (splatPriceABTest({ deal }) ||
      splatPriceDisplayDiscountAndOriginalPrice({ deal })) &&
    !deal?.discount
  )
    return null;

  return (
    <div className="card-spin">
      {showVipSplatComponent ? (
        <div className="card-back">
          <VipDealPriceSplat
            basketTotal={basketTotal}
            className={className}
            coinFlipAnimation={coinFlipAnimation}
            deal={deal}
            discount={discount}
            forcePrice={forcePrice}
            isHideIfNoDiscount={isHideIfNoDiscount}
            isHideSplat={isHideSplat}
            isProductPage={isProductPage}
            visible={visible}
          />
        </div>
      ) : (
        <div className="card-front">
          <NormalPriceSplat
            basketTotal={basketTotal}
            className={className}
            coinFlipAnimation={coinFlipAnimation}
            columnSelection={columnSelection}
            deal={deal}
            discount={discount}
            forcePrice={forcePrice}
            isHideIfNoDiscount={isHideIfNoDiscount}
            isHideSplat={isHideSplat}
            isProductPage={isProductPage}
            rowSelection={rowSelection}
            secondCheckout={secondCheckout}
            showVipSplat={showVipSplat}
            visible={visible}
          />
        </div>
      )}
      <style jsx>{`
        .card-spin {
          transition: 150ms;
          transform-origin: 0 0;
        }
      `}</style>
    </div>
  );
};

DealPriceSplat.propTypes = {
  className: PropTypes.string,
  coinFlipAnimation: PropTypes.bool,
  columnSelection: PropTypes.number,
  deal: PropTypes.object,
  discount: PropTypes.bool,
  forcePrice: PropTypes.bool,
  isHideIfNoDiscount: PropTypes.bool,
  isHideSplat: PropTypes.bool,
  isProductPage: PropTypes.bool,
  rowSelection: PropTypes.number,
  secondCheckout: PropTypes.bool,
  visible: PropTypes.bool,
};

export default DealPriceSplat;
